<template>
  <v-container v-if="hasPermission" fluid>
    <mex-sperm-spinner v-if="organizationViewLoading" :spinnerText="getOrganizationLoadingSpinnerText" />
    <template v-else>
      <v-row justify="center">
        <v-col cols="12">
          <mex-heading content="Organization View" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <mex-sheet color="background" rounded sheetPadding="2">
            <v-row>
              <v-col align-self="center" cols="4"></v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="selectedOrganization"
                  :filled="editMode"
                  :items="organizationNames"
                  :readonly="editMode"
                  color="primaryAccent"
                  hide-details
                  label="Organization Name"
                  outlined
                  dense
                  @change="selectionChanged"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                      <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col align-self="center" cols="4">
                <v-row v-if="organizationData && !organizationViewLoading" justify="end">
                  <v-slide-x-transition>
                    <v-col v-if="editMode" cols="1">
                      <mex-btn icon="mdi-content-save" iconOnly @click="saveChanges" />
                    </v-col>
                  </v-slide-x-transition>
                  <v-slide-x-transition>
                    <v-col v-if="editMode" cols="1">
                      <mex-btn icon="mdi-undo" iconOnly @click="getOrganizationViewData" />
                    </v-col>
                  </v-slide-x-transition>
                  <v-col cols="1">
                    <mex-btn v-if="writeOrganizations" :color="editMode ? 'primary' : ''" icon="mdi-pencil" iconOnly @click="editMode = true" />
                  </v-col>
                  <v-col align-self="center" cols="1">
                    <mex-btn icon="mdi-refresh" iconOnly @click="getOrganizationViewData" />
                  </v-col>
                  <v-col align-self="center" cols="1">
                    <mex-btn
                      v-if="createNotes"
                      :color="noteDialogMinimized ? 'note' : ''"
                      :iconOnly="!noteDialogMinimized"
                      content="Open Note"
                      icon="mdi-file-plus"
                      @click="showNoteDialog = !showNoteDialog"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </mex-sheet>
        </v-col>
      </v-row>
      <mex-sperm-spinner v-if="organizationDataLoading" :spinnerText="getOrganizationLoadingSpinnerText" />
      <template v-else>
        <v-row>
          <v-col cols="8">
            <v-row>
              <v-col class="d-flex" cols="6" style="flex-direction: column">
                <expansion-sheet v-if="organizationData && !organizationViewLoading" color="foreground" rounded>
                  <template v-slot:header>
                    <mex-p content="Organization Info" fontSize="h5" fontWeight="bold-italic" />
                  </template>
                  <template v-slot:content class="mb-1 flex-grow-1">
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="organizationData.name"
                          :filled="!editMode"
                          :readonly="!editMode"
                          color="primaryAccent"
                          dense
                          hide-details
                          label="Organization name"
                          outlined
                        />
                      </v-col>
                    </v-row>
                  </template>
                </expansion-sheet>
              </v-col>
              <v-col class="d-flex" cols="6" style="flex-direction: column">
                <expansion-sheet v-if="systemUserData && !organizationViewLoading" color="foreground" rounded>
                  <template v-slot:header>
                    <mex-p content="System Users" fontSize="h5" fontWeight="bold-italic" />
                  </template>
                  <template v-slot:content class="mb-1 flex-grow-1">
                    <v-select
                      v-model="selectedSystemUsers"
                      :filled="!editMode"
                      :items="systemUserNames"
                      :readonly="!editMode"
                      dense
                      hide-details
                      label="System-Users"
                      multiple
                      outlined
                    >
                      <template v-slot:selection="data">
                        <v-chip class="ma-1" dense>
                          <v-icon class="mr-1" x-small>mdi-account</v-icon>
                          {{ data.item.text }}
                        </v-chip>
                      </template>
                    </v-select>
                    <v-switch
                      v-model="organizationData.compareLocationFeatures"
                      :label="`Location-Feature-check ${ organizationData.compareLocationFeatures ? 'activated' : 'deactivated' } (${organizationData.compareLocationFeatures ? '' : 'no '}warning mails in case of mismatch)`"
                      :readonly="!editMode"
                    />
                  </template>
                </expansion-sheet>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="d-flex" cols="4" style="flex-direction: column">
            <note-sheet v-if="viewNotes && systemUserData && !organizationDataLoading" :notes="notes" @reloadNotes="getNotes" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-row>
              <v-col cols="12">
                <expansion-sheet v-if="organizationData && !organizationViewLoading" color="foreground" rounded>
                  <template v-slot:header>
                    <mex-p content="Clinics" fontSize="h5" fontWeight="bold-italic" />
                  </template>
                  <template v-slot:content>
                    <clinics-table
                      :excludeHeaders="['OrganizationSystemUsers', 'goToOrganization']"
                      :filterData="getPresetFilterData"
                      hideClinicGoTo
                      hideOrgaGoTo
                    />
                  </template>
                </expansion-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-row>
              <v-col cols="12">
                <expansion-sheet v-if="organizationData && !organizationViewLoading" color="foreground" rounded>
                  <template v-slot:header>
                    <mex-p content="Contacts" fontSize="h5" fontWeight="bold-italic" />
                  </template>
                  <template v-slot:content class="mb-1 flex-grow-1">
                    <v-row>
                      <mex-p content="Show / edit orga contacts ..." />
                    </v-row>
                  </template>
                </expansion-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-row>
              <v-col cols="12">
                <expansion-sheet v-if="organizationData && !organizationViewLoading" color="foreground" rounded>
                  <template v-slot:header>
                    <mex-p content="Address" fontSize="h5" fontWeight="bold-italic" />
                  </template>
                  <template v-slot:content class="mb-1 flex-grow-1">
                    <v-row>
                      <v-expansion-panels>
                        <v-expansion-panel v-for="(address, idx) in organizationData.Addresses" :key="idx">
                          <v-expansion-panel-header> {{ address.AddressType.name.toUpperCase() }} </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <address-view :address="address" />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-row>
                  </template>
                </expansion-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </template>
    <note-dialog
      v-if="organizationData && !organizationDataLoading"
      key="organizationViewNoteDialog"
      :preSelection="getNotePreSelection"
      :showNoteDialog="showNoteDialog"
      @accept="getNotes"
      @minimize="setNoteDialogMinimized"
      @reject="setNoteDialogDiscard"
    />
    <save-request :showSaveRequest="showSaveRequest" @closeSaveRequest="showSaveRequest = false" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SaveRequest from '../../components/LicSrvComponents/SaveRequest.vue';
import ClinicsTable from '../../components/LicSrvComponents/ClinicsTable.vue';
import NotesService from '../../services/notes.service';
import OrganizationsService from '../../services/organizations.service';
import SystemUsersService from '../../services/systemUsers.service';
import NoteSheet from '../../components/LicSrvComponents/NoteSheet.vue';
import NoteDialog from '../../components/LicSrvComponents/NoteDialog.vue';
import AddressView from '../../components/LicSrvComponents/AddressView.vue';
import ExpansionSheet from '../../components/LicSrvComponents/ExpansionSheet.vue';
import keyListenerManager from '../../functions/keyListenerManager';
import requiredPermissions from '../../requiredPermissions';
import {assignPropertyIfExists} from "../../functions/assignPropertyIfExists";

export default {
  components: { ClinicsTable, NoteDialog, NoteSheet, AddressView, ExpansionSheet, SaveRequest },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    writeOrganizations() {
      return this.getUserPermissions.includes('write_Customer Administration_Organization Administration');
    },
    viewNotes() {
      return this.getUserPermissions.includes('view_Customer Administration_Note');
    },
    createNotes() {
      return this.getUserPermissions.includes('create_Customer Administration_Note');
    },
    getOrganizationLoadingSpinnerText() {
      return this.clinicViewLoadingText;
    },
    getPresetFilterData() {
      return {
        organizationName: this.staticOrganizationName,
      };
    },
    getNotePreSelection() {
      return {
        organization: this.organizationData.OrganizationID,
        clinic: null,
        location: null,
        systemUsers: this.selectedSystemUsers,
      };
    },
  },
  data() {
    return {
      // visualization
      organizationViewLoadingText: '',
      organizationViewLoading: false,
      organizationDataLoading: false,
      showNoteDialog: false,
      noteDialogMinimized: false,
      showSaveRequest: false,
      // data
      organizationNames: [],
      selectedOrganization: '',
      organizationData: null,
      systemUserData: null,
      selectedSystemUsers: [],
      systemUserNames: [],
      clinicName: '',
      presetFilterData: {
        clinicName: null,
        organizationName: null,
        registerTypeName: null,
        state: null,
        deactivation: null,
        systemUserIDs: null,
      },
      notes: [],
      // edit
      staticOrganizationName: '',
      editMode: false,
      editModeAndSaveShortCutListener: null,
      boundEditModeAndSaveShortCutListener: null,
      hasPermission: false,
    };
  },
  methods: {
    getOrganizationViewData() {
      this.editMode = false;
      this.organizationDataLoading = true;
      this.organizationViewLoadingText = 'loading organization data';
      OrganizationsService.getOrganizationData(this.selectedOrganization)
        .then((organizationResponse) => {
          this.organizationData = organizationResponse.data;
          this.staticOrganizationName = this.organizationData.name;
          this.organizationViewLoadingText = 'loading organization system users';
          SystemUsersService.getSystemUsersByOrganization(this.organizationData.OrganizationID)
            .then((systemUsersResponse) => {
              this.systemUserData = systemUsersResponse.data;
              this.selectedSystemUsers = [];
              this.systemUserData.forEach((systemUser) => {
                this.selectedSystemUsers.push(systemUser.SystemUserID);
              });
              this.getNotes();
            })
            .catch((err) => {
              this.organizationDataLoading = false;
              this.$toast.error(err.response.data);
            });
        })
        .catch((err) => {
          this.$toast.error(err);
          this.organizationDataLoading = false;
        });
    },
    setNoteDialogDiscard() {
      this.showNoteDialog = false;
      this.noteDialogMinimized = false;
    },
    setNoteDialogMinimized() {
      this.showNoteDialog = false;
      this.noteDialogMinimized = true;
    },
    getNotes() {
      this.showNoteDialog = false;
      NotesService.getNotes(this.organizationData.OrganizationID, '-1')
        .then((notesResponse) => {
          this.notes = notesResponse.data;
          this.organizationDataLoading = false;
        })
        .catch((err) => {
          this.organizationDataLoading = false;
          this.$toast.error(err.response.data);
        });
    },
    getSelectData() {
      OrganizationsService.getOrganizationNames()
        .then((organizationResponse) => {
          organizationResponse.data.forEach((orga) => {
            this.organizationNames.push({
              value: orga.OrganizationID,
              text: orga.name,
            });
          });
          SystemUsersService.getSystemUserNames().then((systemUserResponse) => {
            systemUserResponse.data.forEach((systemUser) => {
              this.systemUserNames.push({
                value: systemUser.SystemUserID,
                text: systemUser.username,
              });
            });
          });
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
        });
    },
    saveChanges() {
      OrganizationsService.updateOrganization(
        this.organizationData.OrganizationID,
        this.organizationData.name,
        this.organizationData.customerNumber,
        this.selectedSystemUsers,
        this.organizationData.compareLocationFeatures,
      )
        .then(() => {
          this.$toast.success('Update successful');
          this.editMode = false;
          this.$router.push({
            name: 'ParamsReload',
            params: { name: 'OrganizationView', reloadParams: { id: this.organizationData.OrganizationID } },
          });
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
        });
    },
    manageKeyListener() {
      keyListenerManager.editModeAndSaveShortCut(
        this,
        document,
        'editModeAndSaveShortCutListener',
        'boundEditModeAndSaveShortCutListener',
        'editMode',
        'getOrganizationViewData',
        'saveChanges',
      );
    },
  },
  watch: {
    selectedOrganization: {
      handler() {
        this.getOrganizationViewData();
        this.$store.commit('selectedProperties/setOrganizationView', this.selectedOrganization);
      },
    },
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.boundEditModeAndSaveShortCutListener);
  },
  created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.organizationAdministration, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.getSelectData();
          this.selectedOrganization = assignPropertyIfExists([this.$route.params, this.$route.query], 'id', this.$store, 'organizationView', 'setOrganizationView');

          if (this.getUserPermissions.includes('write_Customer Administration_Organization Administration')) {
            this.manageKeyListener();
          }
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
  beforeRouteLeave(to, from, next) {
    if (this.editMode && !this.showSaveRequest) {
      this.showSaveRequest = true;
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.stickyHeader {
  position: sticky;
  top: 51px;
  z-index: 10;
}
</style>
